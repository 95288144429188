
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import firebase from "firebase/compat/app"
import db from '../../components/firebaseInit.js';
import { User } from '../../models/User'

const user = new User();

export default {
	name: 'EmailVerif',
	mixins: [validationMixin],
	data() {
		return {
			textverifiedEmail: user.getTextEmailStatus()
		}

	},
	validations: {
		
	},
	methods: {
		send(e) {
			e.preventDefault();
			this.$router.push('/login');
			
			setTimeout(() => {
				var toast = null;
			}, 2000);
		}
	},
	beforeCreated() {
	},
	created() {
		console.log(this.$route.query);
		this.user = this.$route.query.email;
	}
}