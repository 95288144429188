import db from '../components/firebaseInit.js';
import firebase from "firebase/compat/app";

export class PostModel {

    constructor() {    
    }

    init(id) {
        if (firebase.auth().currentUser) {
            var docRef = db.collection("posts").doc(id);

            docRef.get().then((doc) => {
                if (doc.exists) {
                    this.themes = doc.data().themes,
                    this.author = doc.data().author,
                    this.description = doc.data().description,
                    this.pari1 = doc.data().pari1,
                    this.pari2 = doc.data().pari2,
                    this.pari3 = doc.data().pari3,
                    this.pari1Status = doc.data().pari1Status,
                    this.pari2Status = doc.data().pari2Status,
                    this.pari3Status = doc.data().pari3Status
                    this.id = id;
                } else {
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

    }

    getPari1StatusValidated() {
        if (this.pari1Status == "validated") return true;
        return false
    }

    getPari1StatusRefused() {
        if (this.pari1Status == "toUpdate") return true;
        return false
    }

    getPari1StatusWaiting() {
        if (this.pari1Status == "waiting") return true;
        return false
    }

    getPari2StatusValidated() {
        if (this.pari2Status == "validated") return true;
        return false
    }

    getPari2StatusRefused() {
        if (this.pari2Status == "toUpdate") return true;
        return false
    }

    getPari2StatusWaiting() {
        if (this.pari2Status == "waiting") return true;
        return false
    }
    getPari3StatusValidated() {
        if (this.pari3Status == "validated") return true;
        return false
    }

    getPari3StatusRefused() {
        if (this.pari3Status == "toUpdate") return true;
        return false
    }

    getPari3StatusWaiting() {
        if (this.pari3Status == "waiting") return true;
        return false
    }

    getAuthor() {
        return (this.Author);
    }

    getId() {
        return (this.id);
    }

    getPost() {
        return (this);
    }
}