<template>

  <div class="modal">
    <h3 class="modal__info">Proposer un nouveau thème</h3>
    <br />
    <h3>
      Je propose 3 paris qui correspondent exactement aux critères attendus :
    </h3>
    <br />
     <ul class="critereul">
    <li class="critere">Date de début et de fin avant que l'info soit connue</li>
    <li class="critere">Moins de 59 caractères par pari</li>
    <li class="critere">Seule réponse possible à tes paris : YES ou NO</li>
    <li class="critere">Respect de notre charte: pas de racisme, sexisme…</li>
    <li class="critere">Relis 3 fois avant de cliquer en bas 😉</li>
    <br />
      </ul>
    <form class="form">
      
      <button class="form__close-btn" @click="hideModal"></button>

      <input
        type="text"
        class="form__input-theme"
        placeholder="Nom du thème (sois précis en 1 seul mot !)"
        :class="{ 'has-error': $v.themes.$invalid && showerror}"
        @input="$v.themes.$touch()"
        v-model="themes"
      />
      <div class="form__input-hint" v-show="showerror">
      <p
        class="form__input-hint"
        v-if="$v.themes.$invalid && !$v.themes.required"
      >
        Ce champ est requis
      </p>
      <p
        class="form__input-hint"
        v-if="$v.themes.$dirty && !$v.themes.minLength"
      >
        Ce champ doit contenir minimum 3 caractères
      </p>
      <p class="form__input-hint" v-if="$v.themes.$dirty && !$v.themes.maxLength">
        Ce champ doit contenir un maximum de 25 charactères
      </p></div>

 

      <input
        type="text"
        class="form__input"
        placeholder="Propose ton 1er pari"
        :class="{ 'has-error': $v.pari1.$invalid && showerror}"
        @input="$v.pari1.$touch()"
        v-model="pari1"
      /> 
    
      <div class="form__input-hint" v-show="showerror">
      <p class="form__input-hint" v-if="$v.pari1.$invalid && !$v.pari1.required">
        Ce champ est requis
      </p>
      <p class="form__input-hint" v-if="$v.pari1.$dirty && !$v.pari1.minLength">
        Ce champ doit contenir minimum 10 caractères
      </p>
      <p class="form__input-hint" v-if="$v.pari1.$dirty && !$v.pari1.maxLength">
        Ce champ doit contenir maximum 59 caractères
      </p></div>
      <p class="limiter">{{charactersLeft}}</p>

      <input
        type="text"
        class="form__input"
        placeholder="Propose ton 2eme pari"
        :class="{ 'has-error': $v.pari2.$invalid && showerror}"
        @input="$v.pari2.$touch()"
        v-model="pari2"
      /><div class="form__input-hint" v-show="showerror">
      <p class="form__input-hint" v-if="$v.pari2.$invalid && !$v.pari2.required">
        Ce champ est requis
      </p>
      <p class="form__input-hint" v-if="$v.pari2.$dirty && !$v.pari2.minLength">
        Ce champ doit contenir minimum 10 caractères
      </p>
      <p class="form__input-hint" v-if="$v.pari2.$dirty && !$v.pari2.maxLength">
        Ce champ doit contenir maximum 59 charactères
      </p></div>
      <p class="limiter">{{charactersLeft2}}</p>

      <input
        type="text"
        class="form__input"
        placeholder="Propose ton 3eme pari"
        :class="{ 'has-error': $v.pari3.$invalid && showerror }"
        @input="$v.pari3.$touch()"
        v-model="pari3"
      /><div class="form__input-hint" v-show="showerror">
      <p class="form__input-hint" v-if="$v.pari3.$invalid && !$v.pari3.required">
        Ce champ est requis.
      </p>
      <p class="form__input-hint" v-if="$v.pari3.$dirty && !$v.pari3.minLength">
        Ce champ doit contenir minimum 10 caractères
      </p>
      <p class="form__input-hint" v-if="$v.pari3.$dirty && !$v.pari3.maxLength">
        Ce champ doit contenir maximum de 59 caractères
      </p></div>
      <p class="limiter">{{charactersLeft3}}</p>
      <button
        type="submit"
        @click="send"
        class="form__btn"
      >
        Envoyer ma proposition
      </button>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import firebase from "firebase/compat/app";
import db from "./firebaseInit";

export default {
  name: "Modal",
  mixins: [validationMixin],
  data() {
    return {
      author: "",
      themes: "",
      pari1: "",
      pari2: "",
      pari3: "",
      status: "",
			showerror: false

    };
  },
	computed: {
     charactersLeft() {
        var char = this.pari1.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      },
      charactersLeft2() {
        var char = this.pari2.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      },
      charactersLeft3() {
        var char = this.pari3.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      }
  },
  validations: {
    themes: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(25),
    },
    pari1: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
    pari2: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
    pari3: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
  },
  methods: {
    send(e) {
      e.preventDefault();
      if (this.$v.$invalid) {
				this.showerror= true;
        if (this.pari1 == "" || this.pari2 == "" || this.pari3 == "") {
        
          this.$toasted.error(`Merci de proposer tes 3 paris avant de valider`, {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
						
					});	
        }
			}
			else {
        this.showerror= false;
        db.collection("posts")
        .add({
          themes: this.themes,
          author: firebase.auth().currentUser.email,
          pari1: this.pari1,
          pari2: this.pari2,
          pari3: this.pari3,
          pari1Status: "waiting", 
          pari2Status: "waiting",
          pari3Status: "waiting",
        })
        .then((docRef) => {
          this.$router.go("/dashboard");
        })
        .catch((err) => console.log(err));
        let toast = this.$toasted.show("Success! Votre Demande a bien été envoyé", {
          theme: "bubble",
          type: "info",
          position: "bottom-right",
          duration: 5000,
      });
      setTimeout(() => {
        toast = null;
      }, 2000);
      this.$emit("closeModal", false);
    }},
    hideModal(e) {
      e.preventDefault();
      this.$emit("closeModal", false);
    },
  },
};

</script>


<style lang="scss" scoped> @import "./Modal.scss"; </style>