import { validationMixin } from "vuelidate";
import { requiredIf, minLength, maxLength } from "vuelidate/lib/validators";

import firebase from "firebase/compat/app";
import db from "../../components/firebaseInit";
import { PostModel } from '../../models/Update.js';

const postM = new PostModel();

export default {
  name: "UpdatePost",
  mixins: [validationMixin],
  data() {
    return {
      author: "",
      themes: "",
      pari1: "",
      pari2: "",
      pari3: "",
      status: "",
      pari1StatusValidated: "",
      pari1StatusRefused: "",
      pari1StatusWaiting: "",
      pari2StatusValidated: "",
      pari2StatusRefused: "",
      pari2StatusWaiting: "",
      pari3StatusValidated: "",
      pari3StatusRefused: "",
      pari3StatusWaiting: "",
			showerror: false
    };
  },
	computed: {
     charactersLeft() {
        var char = this.pari1.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      },
      charactersLeft2() {
        var char = this.pari2.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      },
      charactersLeft3() {
        var char = this.pari3.length,
            limit = 59;

        return (limit - char) + " / " + limit + " caractères restants";
      }
  },
  validations: {
    pari1: {
      requiredIfRef: requiredIf(postM.pari1StatusRefused),
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
    pari2: {
      requiredIfRef: requiredIf(postM.pari2StatusRefused),
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
    pari3: {
      requiredIfRef: requiredIf(postM.pari3StatusRefused),
      minLength: minLength(10),
      maxLength: maxLength(59),
    },
  },
  methods: {
    send(e) {
      e.preventDefault();
      if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {      
      let pari1ToSend = postM.pari1Status;
      let pari2ToSend = postM.pari2Status;
      let pari3ToSend = postM.pari3Status;
      
      if (postM.pari1 != this.pari1 && postM.pari1Status == "toUpdate") {
        pari1ToSend = "waiting";
      } 
      else {
        this.pari1 = postM.pari1;
      }
      if (postM.pari2 != this.pari2 && postM.pari2Status == "toUpdate") {  
        pari2ToSend = "waiting";
      }
      else {
        this.pari2 = postM.pari2;
      }
      if (postM.pari3 != this.pari3 && postM.pari3Status == "toUpdate") {
        pari3ToSend = "waiting";
      }
      else {
        this.pari3 = postM.pari3;
      }

      db.collection('posts').doc(postM.getId()).set({
        author: firebase.auth().currentUser.email,
        themes: this.themes,
        pari1: this.pari1,
        pari2: this.pari2,
        pari3: this.pari3,
        pari1Status: pari1ToSend,
        pari2Status: pari2ToSend,
        pari3Status: pari3ToSend,

      }, { merge: true })
        .then((docRef) => {
          /*this.$toasted.show("Success! Votre demande a bien été mise à jour", {
            theme: "bubble",
            type: "info",
            position: "bottom-right",
            duration: 5000,
          });*/
          this.$router.push("/dashboard");
        })

        .catch((err) => console.log(err));
      setTimeout(() => {
        let ctoast = null;
      }, 2000);
      this.$emit("CloseUpdatePost", false);
    }},
    hideUpdatePost(e) {
      e.preventDefault();
      this.$router.push('/dashboard');
    }
  },
  beforeRouteEnter(to, from, next) {
    //console.log(to.params.id, to.params);
    postM.init(to.params.id);
    db.collection('posts').doc(to.params.id).get()
      .then(doc => {
        if (doc.exists) {
          //console.log("beforeRouteEnter Document data:", doc.data());
          next(vm => {
            vm.pari1StatusValidated = postM.getPari1StatusValidated();
            vm.pari1StatusRefused = postM.getPari1StatusRefused();
            vm.pari1StatusWaiting = postM.getPari1StatusWaiting();
            vm.pari2StatusValidated = postM.getPari2StatusValidated();
            vm.pari2StatusRefused = postM.getPari2StatusRefused();
            vm.pari2StatusWaiting = postM.getPari2StatusWaiting();
            vm.pari3StatusValidated = postM.getPari3StatusValidated();
            vm.pari3StatusRefused = postM.getPari3StatusRefused();
            vm.pari3StatusWaiting = postM.getPari3StatusWaiting();
            vm.themes = doc.data().themes,
            vm.author = doc.data().author,
            vm.description = doc.data().description,
            vm.pari1 = doc.data().pari1,
            vm.pari2 = doc.data().pari2,
            vm.pari3 = doc.data().pari3,
            vm.pari1Status = doc.data().pari1Status,
            vm.pari2Status = doc.data().pari2Status,
            vm.pari3Status = doc.data().pari3Status
          })
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

  },
  created() {

  }

};