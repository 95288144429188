
import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength, email, numeric } from 'vuelidate/lib/validators';
import firebase from "firebase/compat/app"
import db from '../../components/firebaseInit.js';
import { User } from '../../models/User'

const user = new User();

export default {
	name: 'CompleteProfil',
	mixins: [validationMixin],
	data() {
		return {
			name: "",
			nickname: "",
			telegram: "",
			tel: "",
			twitter: "",
			country: "",
			date: "",
			textverifiedEmail: "",
			user: "",
			email: "",
			showerror: false
		}

	},
	validations: {
		name: {
			required,
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		nickname: {
			required,
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		tel: {
			numeric,
			maxLength: maxLength(12),
			minLength: minLength(6)
		},
		telegram: {
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		twitter: {
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		country: {
			required,
			minLength: minLength(2),
			maxLength: maxLength(2)
		},
		date: {
			required,
			maxLength: maxLength(10),
			minLength: minLength(10)

		}
	},
	methods: {
		send(e) {
			e.preventDefault();

			console.log(this.name, this.nickname, this.tel, this.date, this.country, this.telegram);

			if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {

			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.reload().then(() => {

					user.setEmailStatus(firebase.auth().currentUser.emailVerified);
					user.setEmail(firebase.auth().currentUser.email);

					var usersRef = db.collection("usersdata");

					usersRef.doc(firebase.auth().currentUser.email).set({
						name: this.name,
						nickname: this.nickname,
						tel: this.tel,
						telegram: this.telegram,
						twitter: this.twitter,
						country: this.country,
						date: this.date,
					})
						.then(user => {
							this.$toasted.show("Succes! Profil mise à jour", {
								theme: "bubble",
								type: 'info',
								position: "bottom-center",
								duration: 5000
							});
							this.$router.push('/dashboard');
						},
							err => {
								this.$toasted.error(`${err.message}`, {
									theme: "bubble",
									type: 'info',
									position: "bottom-center",
									duration: 5000
								});
							})

					setTimeout(() => {
						var toast = null;
					}, 2000);

				})
			}
			}
		},
		logout() {
			firebase.auth().signOut()
				.then(() => {
					this.$toasted.show("Déconnexion réussi", {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
					});
					this.$router.push('/')
				})
		}
	},
	beforeRouteEnter(to, from, next) {
		if (firebase.auth().currentUser) {
			next(vm => {
				vm.email = firebase.auth().currentUser.email;
			})
		}
		else {
			console.log("No such document!");
		}
	},
	created() {

	}
}