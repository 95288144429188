import db from '../components/firebaseInit.js';

export class PostsImport {

    constructor() {
        this.posts = [];
		this.postsValidated = [];
		this.postsWaitingValidated = [];
        this.postsRefused = [];
        this.postsEcouteur = false;
    }

    init(email) {
        
        var docRef = db.collection('posts').where("author", "==", email)       
		
			this.setProfilEcouteur(true);
			docRef.onSnapshot(querySnapshot => {
                this.posts = [];
                this.postsValidated = [];
                this.postsWaitingValidated = [];
                this.postsRefused = [];

				querySnapshot.forEach(doc => {
                    let posttmp = {};
                    posttmp = this.setStatus(doc.data());
					this.setPost(posttmp, doc.id);
					if (posttmp.verified) this.setPostValidated(posttmp, doc.id);
					if (posttmp.waiting) this.setPostWaitingValidated(posttmp, doc.id);
					if (posttmp.refused) this.setPostsRefused(posttmp, doc.id);
				})
            });
    }
    
    setStatus(post) {
        if (post.pari1Status == "validated" && post.pari2Status == "validated" && post.pari3Status == "validated") {
            post.verified = true;
            post.refused = false;
            post.waiting = false;
            post.status = "validated";
        }        
        if (post.pari1Status == "toUpdate" || post.pari2Status == "toUpdate" || post.pari3Status == "toUpdate") {
            post.verified = false;
            post.refused = true;
            post.waiting = false;
            post.status =    "toUptade";
        } 
        else if (post.pari1Status == "waiting" || post.pari2Status == "waiting" || post.pari3Status == "waiting"){
            post.verified = false;
            post.refused = false;
            post.waiting = true;
            post.status = "waiting";
        }       
        return (post);
    }

    setPost(post, id) {
        post.id = id;

        this.posts.push(post);
    }

    setPostValidated(post, id) {
        post.id = id;
        this.postsValidated.push(post);
    }

    setPostWaitingValidated(post, id) {
        post.id = id;
        this.postsWaitingValidated.push(post);
    }

    setClearPosts() {
        this.posts = [];
		this.postsValidated = [];
		this.postsWaitingValidated = [];
        this.postsRefused = []; 
    }

    setPostsRefused(post, id) {
        post.id = id;
        this.postsRefused.push(post);
    }

    setProfilEcouteur(status) {
        this.ProfilEcouteur = status;
    }

    getAllPosts() {
        return (this.posts);
    }

    getValidatedPosts() {
        return (this.postsValidated);
    }

    getunVerifiedPosts() {
        return (this.postsWaitingValidated);
    }

    getunRefusedPosts() {
        return (this.postsRefused);
    }
}