import Home from '../views/Home/Home.vue'
import Login from '../views/Login/Login.vue'
import Register from '../views/Register/Register.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import Profil from '../views/Profil/Profil.vue'
import Forget from '../views/Forget/Forget.vue'
import EmailVerif from '../views/EmailVerif/EmailVerif.vue'
import CompleteProfil from '../views/CompleteProfil/Profil.vue'
import Update from '../views/UpdatePost/UpdatePost.vue'

export default [
	//{ path: '/', name: 'Home', component: Home },
	{ path: '/login', name: 'Login', component: Login, meta: { requiresGuest: true }  },
	{ path: '/forget', name: 'Forget', component: Forget },
	{ path: '/register', name: 'Register', component: Register, meta: { requiresGuest: true }  },
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
	{ path: '/profil', name: 'Profil', component: Profil, meta: { requiresAuth: true } },
	{ path: '/completeProfil', name: 'CompleteProfil', component: CompleteProfil, meta: { requiresAuth: true } },
	{ path: '/emailVerif', name: 'Email Verification', component: EmailVerif},
	{ path: '/update/:id', name: 'Update', component: Update, meta: { requiresAuth: true }  },
]