import db from '../../components/firebaseInit.js';
import Posts from '../../components/Posts';
import Modal from '../../components/Modal';
import { PostsImport } from '../../models/Posts';
import firebase from "firebase/compat/app";

const posts = new PostsImport();

function deleteProperties(objectToClean) {
	for (var x in objectToClean) if (objectToClean.hasOwnProperty(x)) delete objectToClean[x];
  }

function  setStatus(post) {
	if (post.pari1Status == "validated" && post.pari2Status == "validated" && post.pari3Status == "validated") {
		post.verified = true;
		post.refused = false;
		post.waiting = false;
		post.status = "validated";
	}        
	if (post.pari1Status == "toUpdate" || post.pari2Status == "toUpdate" || post.pari3Status == "toUpdate") {
		post.verified = false;
		post.refused = true;
		post.waiting = false;
		post.status = "toUptade";
	} 
	else if (post.pari1Status == "waiting" || post.pari2Status == "waiting" || post.pari3Status == "waiting"){
		post.verified = false;
		post.refused = false;
		post.waiting = true;
		post.status = "waiting";
	}       
	return (post);
}

export default {
	name: 'Dashboard',
	components: {
		Posts,
		Modal
	},
	data() {
		return {
			posts: posts.posts,
			PostsValidated: posts.postsValidated,
			PostsWaitingValidated: posts.postsWaitingValidated,
			modalIsOpen: false,
			user: null,
			toggle: true,
			toggle2: false,
			toggle3: false
		}
	},
	computed: {
		getDate() {
			let today = new Date();
			let dd = String(today.getDate()).padStart(2, '0');
			let mm = String(today.getMonth() + 1).padStart(2, '0');
			let yyyy = today.getFullYear();

			return today = `${dd}.${mm}.${yyyy}`;
		}
	},
	methods: {
		openModal() {
			this.modalIsOpen = true;
		},
		closeModal() {
			this.modalIsOpen = false;
		},
		logout() {
			firebase.auth().signOut()
				.then(() => {
					this.$toasted.show("Déconnexion réussi", {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
					});
					this.$router.push('/');
				})
		},
		profil() {
			this.$router.push('/profil');
		}
	},
	beforeRouteEnter(to, from, next) {

		if (firebase.auth().currentUser) {
			let posts = [];
			let postsValidated = [];
			let postsWaitingValidated = [];
			let postsRefused = [];
			var docRef = db.collection('posts').where("author", "==", firebase.auth().currentUser.email)
			
			docRef.onSnapshot(querySnapshot => {
				deleteProperties(posts);
				deleteProperties(postsValidated);
				deleteProperties(postsRefused);
				deleteProperties(postsWaitingValidated);

				querySnapshot.forEach(doc => {
					let posttmp = {};
					posttmp = setStatus(doc.data());
					posttmp.id = doc.id;
					posts.push(posttmp);
					if (posttmp.verified) {
						postsValidated.push(posttmp);
					}
					if (posttmp.waiting) {
						postsWaitingValidated.push(posttmp);
					}
					if (posttmp.refused) {
						postsRefused.push(posttmp);
					}
					
				})
			})
			next(vm => {
				vm.posts = posts;
				vm.posts= posts;
				vm.PostsValidated= postsValidated;
				vm.PostsWaitingValidated= postsWaitingValidated;
				//this.$router.push('/dashboard');
			})
		}

	},
	async created() {
		if (firebase.auth().currentUser) {
			this.user = firebase.auth().currentUser.email
			db.collection("usersdata").doc(firebase.auth().currentUser.email)
				.get().then((doc) => {
					if (doc.exists) {
						if (firebase.auth().currentUser.emailVerified != true) {
							this.$toasted.error("Veuillez vérifier votre email", {
								theme: "bubble",
								type: 'info',
								position: "bottom-center",
								duration: 5000
							});
							console.log("Veuillez vérifier votre email");
							this.$router.push('/emailVerif');
						}
					} else {
						this.$toasted.error("Veuillez compléter votre profil", {
							theme: "bubble",
							type: 'info',
							position: "bottom-center",
							duration: 5000
						});
						console.log("Veuillez compléter votre profil");
						this.$router.push('/profil');
					}
				});

			let status = await posts.init(firebase.auth().currentUser.email);
		}
	},
}
