import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import firebase from "firebase/compat/app"
import db from '../../components/firebaseInit.js';
import {ErrorTextFirebase}  from "../../models/Error.js";


export default {
	name: 'Login',
	mixins: [validationMixin],
	data() {
		return {
			mail: '',
			password: '',
			showerror: false
		}
	},
	validations: {
		mail: {
			required,
			email
		},
		password: {
			required,
			minLength: minLength(5)
		}
	},
	methods: {
		send(e) {
			e.preventDefault();

			if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {
			
			firebase.auth().signInWithEmailAndPassword(this.mail, this.password)
				.then(user => {
					///// CHECK IF EMAIL IS VERIFIED ////
					if (firebase.auth().currentUser.emailVerified != true) {
						this.$toasted.error("Veuillez vérifier votre email", {
							theme: "bubble",
							type: 'info',
							position: "bottom-center",
							duration: 5000
						});
						console.log("Veuillez vérifier votre email");
						firebase.auth().signOut()
							.then(() => {
								this.$router.push('/login');
							})
					} else {
						
							///// CHECK ACCOUNT IS PROFIL COMPLETE ////

							var docRef = db.collection("usersdata").doc(firebase.auth().currentUser.email);

							docRef.get().then((doc) => {
								if (doc.exists) {
									console.log("Profil Already complete redirecting to dashboard:");
									this.$router.push('/dashboard');

								} else {
									this.$toasted.error("Veuillez compléter votre profil", {
										theme: "bubble",
										type: 'info',
										position: "bottom-center",
										duration: 5000
									});
									console.log("Veuillez compléter votre profil");
									this.$router.push('/completeProfil');
								}
							});
					}
				},
				err => {
					var ErreurText = ErrorTextFirebase(err.code);
					console.log(ErreurText);
					this.$toasted.error(`${ErreurText}`, {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
						
					});		
				})
			setTimeout(() => {
				var toast = null;
			}, 2000);
		}
		},
		home() {
			this.$router.push('/');
		}
	}
}