// Import the functions you need from the SDKs you need
/*import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";*/
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//const firebaseConfig = 
export default {
  apiKey: "AIzaSyBP1W3gVWhlioB3UjXL5LT6qBuaTgPIRIs",
  authDomain: "yoncreatoradmission-870d7.firebaseapp.com",
  projectId: "yoncreatoradmission-870d7",
  storageBucket: "yoncreatoradmission-870d7.appspot.com",
  messagingSenderId: "992150319664",
  appId: "1:992150319664:web:d877a25e111ef6b198b3f5",
  measurementId: "G-151PZRZ5KW"
};
/*
// Initialize Firebase
//const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);*/