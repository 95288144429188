import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted'
import firebase from "firebase/compat/app"
import './components/firebaseInit'
import Particles from "particles.vue";
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.config.productionTip = false;
Vue.use(Particles);

let app;
firebase.auth().onAuthStateChanged(user => {
	if(!app) {
		app = new Vue({
			router,
			render: h => h(App)
			}).$mount('#app')
	}
})