<template>
  <div class="post">   
    <div class="trois">
      <div class="uno">
        <h3 class="post__theme">{{ post.themes }}</h3>
         <div class= "deletedemande" v-show="post.waiting"> 
         <button class="nav-button" @click="deletePost(post.id)">
          <img class="icon-delete" src="../assets/delete.png"> <span class="buttononpost">Supprimer la demande </span></button>
        </div>
        <div class= "updatedemande" v-show="post.refused"> 
           <button class="nav-button" @click="OpenUpdatePost(post)">
            	<img class="icon-delete" src="../assets/update.png"><span class="buttononpost"> Modifier la demande</span></button>
        </div>
        
      </div>
      <div class="deux">
        <div class= "status__Verified" v-show="post.verified"> Validé</div>
        <div class= "status__Refused" v-show="post.refused"> À revoir</div>
        <div class= "status__Waiting" v-show="post.waiting"> En cours de validation</div>     
       
      </div>
    </div>

     <div class="post__align" >
      <div class="col"> <p class="tittle"> Première proposition
        <img v-show="validatedstr==post.pari1Status" class="icon-redcross" src="../assets/validateicon.png">
        <img v-show="toUpdatedstr==post.pari1Status" class="icon-redcross" src="../assets/redcross.png">
        </p>
        <br>
        <div class="Text" v-show="post.verified"> {{ post.pari1 }} </div>
        <div class="Text" v-show="post.refused"> {{ post.pari1 }} </div>
        <div class="Text__Waiting" v-show="post.waiting"> {{ post.pari1 }} </div>

      </div>
      <div class="col"> <p class="tittle"> Deuxième proposition 
        <img v-show="validatedstr==post.pari2Status" class="icon-redcross" src="../assets/validateicon.png">
        <img v-show="toUpdatedstr==post.pari2Status" class="icon-redcross" src="../assets/redcross.png">
        </p>   
        <br>
        <div class="Text" v-show="post.verified"> {{ post.pari2 }} </div>
        <div class="Text" v-show="post.refused"> {{ post.pari2 }} </div>
        <div class="Text__Waiting" v-show="post.waiting"> {{ post.pari2 }} </div>
      </div>
      <div class="col"> <p class="tittle"> Troisième proposition 
        <img v-show="validatedstr==post.pari3Status" class="icon-redcross" src="../assets/validateicon.png">
        <img v-show="toUpdatedstr==post.pari3Status" class="icon-redcross" src="../assets/redcross.png">
        </p>   
        <br>
        <div class="Text" v-show="post.verified"> {{ post.pari3 }} </div>
        <div class="Text" v-show="post.refused"> {{ post.pari3 }} </div>
        <div class="Text__Waiting" v-show="post.waiting"> {{ post.pari3 }} </div>
      </div>
    </div>
  </div>
</template>

<script>

import UpdatePost from "../views/UpdatePost/UpdatePost";
import firebase from "firebase/compat/app";
import db from '../components/firebaseInit.js';

export default {
  name: "Posts",
  props: ["post"],
  components: {
		UpdatePost
	},
  	data() {
	  	return {
		  	toggle: true,
        UpdatePostIsOpen: false,
        validatedstr: "validated",
        toUpdatedstr: "toUpdate",
        waitingdstr: "waiting",
        posttmp: {}
		  }},
    methods: {
		OpenUpdatePost(post) {
      this.$router.push('/update/'+ post.id);
		},
		CloseUpdatePost() {
      this.posttmp = {};
		},
      deletePost(id) {
         console.log(id, "id from function");
		    if (confirm("Voulez vous vraiment supprimer cette demande?")) {
			    if (firebase.auth().currentUser) { 
            console.log(firebase.auth().currentUser.email, "inside delete post after confirm");
   
            db.collection("posts").doc(id).delete().then(() => {
              console.log("Document successfully deleted!");                          
            })
            .then(() => {
              this.$router.go();
              this.$toasted.show("Demande supprimer", {
								theme: "bubble",
								type: 'info',
								position: "bottom-center",
								duration: 5000
							});  
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });				
			    }}
      }
    }
	};

</script>

<style lang="scss" scoped> @import "./Posts.scss"; </style>