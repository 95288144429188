export function ErrorTextFirebase(errcode) {
    return (ErrorTextIF(errcode).replace('/', ''));
}

export function ErrorTextIF(errcode) {
    console.log(errcode);
    if (errcode == "auth/user-not-fond") return "L'email fournis ne correspond à aucun compte connu. Essayez de vous inscrire";
    if (errcode == "auth/too-many-requests") return "Trop de requêtes envoyer, veuillez patienter"
    if (errcode == "auth/wrong-password") return "Le mot de passe est incorrect."
    if (errcode == "auth/email-already-in-use") return "L/'e-mail fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un email unique.";
    if (errcode == "auth/claims-too-large") return "La charge utile des revendications fournie à setCustomUserClaims() est supérieure à la taille maximale autorisée de 1000 octets.";
    if (errcode == "auth/email-already-exists") return "L/'e-mail fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un email unique.";
    if (errcode == "auth/id-token-expired") return "Le jeton d/'identification Firebase fourni a expiré.";
    if (errcode == "auth/id-token-revoked") return "Le jeton d/'identification Firebase a été révoqué.";
    if (errcode == "auth/insufficient-permission") return "Les informations d/'identification utilisées pour initialiser le SDK Admin ne disposent pas des autorisations suffisantes pour accéder à la ressource d/'authentification demandée. Reportez - vous à mettre en place un projet Firebase pour la documentation sur la façon de générer une information d/' identification avec les autorisations appropriées et l/' utiliser pour authentifier l/'administrateur SDKs.";
    if (errcode == "auth/internal-error") return "Le serveur d/'authentification a rencontré une erreur inattendue lors de la tentative de traitement de la demande. Le message d//'erreur doit contenir la réponse du serveur d/'authentification contenant des informations supplémentaires. Si le voyant reste d/'erreur, s/'il vous plaît signaler le problème à notre rapport de bug canal de support."
    if (errcode == "auth/invalid-argument") return "Un argument non valide a été fourni à une méthode d/'authentification. Le message d/'erreur doit contenir des informations supplémentaires."
    if (errcode == "auth/invalid-claims") return "Les attributs de revendications personnalisées fournies à setCustomUserClaims() ne sont pas valides."
    if (errcode == "auth/invalid-continue-uri") return "L/'URL de continuation doit être une chaîne d/'URL valide."
    if (errcode == "auth/invalid-creation-time") return "L/'heure de création doit être une chaîne de date UTC valide."
    if (errcode == "auth/invalid-credential") return "Les informations d/'identification utilisées pour authentifier les SDK Admin ne peuvent pas être utilisées pour effectuer l/'action souhaitée. Certaines méthodes d/'authentification telles que createCustomToken() et verifyIdToken() nécessitent le SDK pour être initialisé avec un diplôme de certificat , par opposition à un jeton d/' actualisation ou de l/' application des titres de compétences par défaut. Voir Initialiser le SDK pour la documentation sur la façon d/'authentifier les SDKs d/'administration avec un titre de certificat."
    if (errcode == "auth/invalid-disabled-field") return "La valeur fournie pour les disabled propriété utilisateur est invalide. Ce doit être un booléen."
    if (errcode == "auth/invalid-display-name") return "La valeur fournie pour la displayName propriété utilisateur est invalide. Il doit s/'agir d/'une chaîne non vide."
    if (errcode == "auth/invalid-dynamic-link-domain") return "Le domaine de lien dynamique fourni n/'est pas configuré ou autorisé pour le projet en cours."
    if (errcode == "auth/invalid-email") return "La valeur fournie pour l/' email - email propriété utilisateur est invalide. Il doit s/'agir d/'une chaîne d/'adresse e-mail."
    if (errcode == "auth/invalid-email-verified") return "La valeur fournie pour la emailVerified propriété utilisateur est invalide. Ce doit être un booléen."
    if (errcode == "auth/invalid-hash-algorithm") return "L/'algorithme de hachage doit correspondre à l/'une des chaînes de la liste des algorithmes pris en charge."
    if (errcode == "auth/invalid-hash-block-size") return "La taille du bloc de hachage doit être un nombre valide."
    if (errcode == "auth/invalid-hash-derived-key-length") return "La longueur de clé dérivée du hachage doit être un nombre valide."
    if (errcode == "auth/invalid-hash-key") return "La clé de hachage doit être un tampon d/'octets valide."
    if (errcode == "auth/invalid-hash-memory-cost") return "Le coût de la mémoire de hachage doit être un nombre valide."
    if (errcode == "auth/invalid-hash-parallelization") return "La parallélisation de hachage doit être un nombre valide."
    if (errcode == "auth/invalid-hash-rounds") return "Les tours de hachage doivent être un nombre valide."
    if (errcode == "auth/invalid-hash-salt-separator") return "Le champ séparateur de sel de l/'algorithme de hachage doit être un tampon d/'octets valide."
    if (errcode == "auth/invalid-id-token") return "Le jeton d/'identification fourni n/'est pas un jeton d/'identification Firebase valide."
    if (errcode == "auth/invalid-last-sign-in-time") return "La dernière heure de connexion doit être une chaîne de date UTC valide."
    if (errcode == "auth/invalid-page-token") return "La page suivante prévue jeton dans listUsers() est invalide. Il doit s/'agir d/'une chaîne valide non vide."
    if (errcode == "auth/invalid-password") return "La valeur fournie pour le password de password propriété utilisateur est invalide. Il doit s/'agir d/'une chaîne d/'au moins six caractères."
    if (errcode == "auth/invalid-password-hash") return "Le hachage du mot de passe doit être un tampon d/'octets valide."
    if (errcode == "auth/invalid-password-salt") return "Le mot de passe doit être un tampon d/'octets valide."
    if (errcode == "auth/invalid-phone-number") return "La valeur fournie pour l/' phoneNumber est invalide. Il doit s/'agir d/'une chaîne d/'identifiant conforme à la norme E.164 non vide."
    if (errcode == "auth/invalid-photo-url") return "La valeur fournie pour la photoURL propriété utilisateur est invalide. Il doit s/'agir d/'une URL de chaîne."
    if (errcode == "auth/invalid-provider-data") return "Le providerData doit être un tableau valide d/'objets UserInfo."
    if (errcode == "auth/invalid-provider-id") return "Le providerId doit être une chaîne d/'identifiant de fournisseur prise en charge valide."
    if (errcode == "auth/invalid-oauth-responsetype") return "Seulement exactement un OAuth responseType doit être réglé sur true."
    if (errcode == "auth/invalid-session-cookie-duration") return "La durée du cookie de session doit être un nombre valide en millisecondes compris entre 5 minutes et 2 semaines."
    if (errcode == "auth/invalid-uid") return "La condition uid doit être une chaîne non vide avec au maximum 128 caractères."
    if (errcode == "auth/invalid-user-import") return "L/'enregistrement d/'utilisateur à importer n/'est pas valide."
    if (errcode == "auth/maximum-user-count-exceeded") return "Le nombre maximal autorisé d/'utilisateurs à importer a été dépassé."
    if (errcode == "auth/missing-android-pkg-name") return "Un nom de package Android doit être fourni si l/'application Android doit être installée."
    if (errcode == "auth/missing-continue-uri") return "Une URL de continuation valide doit être fournie dans la demande."
    if (errcode == "auth/missing-hash-algorithm") return "L/'importation d/'utilisateurs avec des hachages de mot de passe nécessite que l/'algorithme de hachage et ses paramètres soient fournis."
    if (errcode == "auth/missing-ios-bundle-id") return "Il manque un ID de lot à la demande."
    if (errcode == "auth/missing-uid") return "Un uid identificateur est requis pour l/'opération en cours."
    if (errcode == "auth/missing-oauth-client-secret") return "Le secret client de configuration OAuth est requis pour activer le flux de code OIDC."
    if (errcode == "auth/operation-not-allowed") return "Le fournisseur de connexion fourni est désactivé pour votre projet Firebase. Activez - le à partir du signe dans la méthode section de la console Firebase."
    if (errcode == "auth/phone-number-already-exists") return "La condition phoneNumber est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir une expérience unique phoneNumber ."
    if (errcode == "auth/project-not-found") return "Aucun projet Firebase n/'a été trouvé pour les identifiants utilisés pour initialiser les SDK Admin. Reportez - vous à mettre en place un projet Firebase pour la documentation sur la façon de générer un titre pour votre projet et de l/' utiliser pour authentifier l/'administrateur SDKs."
    if (errcode == "auth/reserved-claims") return "Une ou plusieurs demandes d/'utilisateurs personnalisés fournis à setCustomUserClaims() sont réservés. Par exemple, OIDC revendications spécifiques tels que (sous, IAT, iss, exp, aud, auth_time, etc.) ne doivent pas être utilisés comme clés pour les demandes personnalisées."
    if (errcode == "auth/session-cookie-expired") return "Le cookie de session Firebase fourni a expiré."
    if (errcode == "auth/session-cookie-revoked") return "Le cookie de session Firebase a été révoqué."
    if (errcode == "auth/uid-already-exists") return "Le fourni uid est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un cadre unique uid ."
    if (errcode == "auth/unauthorized-continue-uri") return "Le domaine de l'URL de continuation n'est pas sur liste blanche. Ajoutez le domaine à la liste blanche dans la console Firebase."
    if (errcode == "auth/user-not-found") return "Il n'existe aucun utilisateur inscrit correspondant à l'identifiant fourni."
    return "Erreur Inconnu"
}