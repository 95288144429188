import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength, email, numeric } from 'vuelidate/lib/validators';
import firebase from "firebase/compat/app"
import db from '../../components/firebaseInit.js';
import { User } from '../../models/User'

const user = new User();

export default {
	name: 'Profil',
	mixins: [validationMixin],
	data() {
		return {
			email: user.email,
			name: user.name,
			nickname: user.nickname,
			telegram: user.telegram,
			tel: user.tel,
			twitter: user.twitter,
			country: user.country,
			date: user.date,
			user: user.email,
			showerror: false
		}

	},
	validations: {
		name: {
			required,
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		nickname: {
			required,
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		tel: {
			numeric,
			maxLength: maxLength(12),
			minLength: minLength(6)
		},
		telegram: {
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		twitter: {
			maxLength: maxLength(49),
			minLength: minLength(2)
		},
		country: {
			required,
			minLength: minLength(2),
			maxLength: maxLength(2)
		},
		date: {
			required,
			maxLength: maxLength(10),
			minLength: minLength(10)

		}
	},
	methods: {
		send(e) {
			e.preventDefault();

			if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {


			if (firebase.auth().currentUser) {
				firebase.auth().currentUser.reload().then(() => {

					user.setEmail(firebase.auth().currentUser.email);
					var usersRef = db.collection("usersdata");
					usersRef.doc(firebase.auth().currentUser.email).set({
						name: this.name,
						nickname: this.nickname,
						tel: this.tel,
						telegram: this.telegram,
						twitter: this.twitter,
						country: this.country,
						date: this.date,
					})
						.then(user => {
							this.$toasted.show("Succes! Profil mise à jour", {
								theme: "bubble",
								type: 'info',
								position: "bottom-center",
								duration: 5000
							});
							this.$router.push('/profil');
							//return this.$router.push('/profil'); // this is now returning the promise
							//await router.push('/location')
						},
							err => {
								this.$toasted.error(`${err.message}`, {
									theme: "bubble",
									type: 'info',
									position: "bottom-center",
									duration: 5000
								});
							})

					setTimeout(() => {
						var toast = null;
					}, 2000);

				})
			}
			}
		},
		logout() {
			firebase.auth().signOut()
				.then(() => {
					this.$toasted.show("Déconnexion réussi", {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
					});
					this.$router.push('/');
				})
		},
		dashboard() {
			this.$router.push('/dashboard');
		}
	},
	beforeRouteEnter(to, from, next) {
		if (firebase.auth().currentUser) {
			firebase.auth().currentUser.reload().then(() => {

				user.setEmail(firebase.auth().currentUser.email);
				var docRef = db.collection("usersdata").doc(user.getEmail());
				docRef.onSnapshot((doc) => {
					if (doc.exists) {
						user.initWithFirestoreData(doc.data());
						user.setEmailStatus(firebase.auth().currentUser.emailVerified);
						next(vm => {
							user.initWithFirestoreData(doc.data());
							user.setEmailStatus(firebase.auth().currentUser.emailVerified);
							user.ProfilEcouteur = true;
							vm.email = firebase.auth().currentUser.email; 
							vm.name = doc.data().name;
							vm.nickname = doc.data().nickname;
							vm.telegram = doc.data().telegram;
							vm.tel = doc.data().tel;
							vm.twitter = doc.data().twitter;
							vm.country = doc.data().country;
							vm.date = doc.data().date;
							vm.user = doc.data().email;
						})
					}
					else {
						console.log("No such document!");
					}
				});
			})
		}
	},
	created() {

		if (firebase.auth().currentUser) {
			firebase.auth().currentUser.reload().then(() => {

				user.setEmail(firebase.auth().currentUser.email);
				user.setEmailStatus(firebase.auth().currentUser.emailVerified);

				var docRef = db.collection("usersdata").doc(user.getEmail());

				docRef.get().then((doc) => {
					if (doc.exists) {
						user.initWithFirestoreData(doc.data());
						user.setEmailStatus(firebase.auth().currentUser.emailVerified);

					} else {
						console.log("No such document!");
					}
				});
			})
		}
	}
}