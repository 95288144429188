import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import firebase from "firebase/compat/app"

export default {
	name: 'Forget',
	mixins: [ validationMixin ],
	data() {
		return {
			mail: '',
			showerror: false
		}
	},
	validations: {
		mail: {
			required,
			email
		}
	},
	methods: {
		send(e) {
			e.preventDefault();

			if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {

			firebase
			.auth()
			.sendPasswordResetEmail(this.mail)
			.then(() => {
			  this.emailSending = false;
			  let toast = this.$toasted.show("Mail envoyer avec succès!", {
				theme: "bubble",
				type: 'info',
				position: "bottom-center",
				duration : 5000
			});
			  this.$router.push('/login');
			  console.log("Email sended");
			})
			.catch(error => {
			  this.emailSending = false;
			  this.error = error.message;
			  console.log(this.error);
			  let toast = this.$toasted.show(`${this.error}`, {
				theme: "bubble",
				type: 'info',
				position: "bottom-center",
				duration : 5000
			});
			});
			
			setTimeout(() => {
				toast = null;
			}, 2000);
		}
	}
	}
}