import { validationMixin } from 'vuelidate';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { getAnalytics, setUserProperties } from "firebase/analytics";
import firebase from "firebase/compat/app"
import {ErrorTextFirebase}  from "../../models/Error.js";

export default {
	name: 'Register',
	mixins: [validationMixin],
	data() {
		return {
			mail: '',
			password: '',
			confirmPassword: '',
			showerror: false
		}
	},
	validations: {
		mail: {
			required,
			email
		},
		password: {
			required,
			minLength: minLength(5)
		},
		confirmPassword: { 
			required,
			sameAsRawValue: sameAs('password')
		}
	},
	methods: {
		send(e) {
			e.preventDefault();

			
			if (this.$v.$invalid) {
				this.showerror= true;
			}
			else {

			firebase.auth().createUserWithEmailAndPassword(this.mail, this.password)
				.then(() => {
					this.$toasted.show(`Sucess! Votre compte à été créé!`, {
						theme: "bubble",
						type: 'info',
						position: "bottom-center",
						duration: 5000
					});
					const user = firebase.auth().currentUser;
					user.sendEmailVerification();

					const query = {email: this.mail};
					firebase.auth().signOut()
					.then(() => {
						this.$router.push({path: "/emailVerif", query: query});
					});
				},
					err => {
						var ErreurText = ErrorTextFirebase(err.code);
						console.log(ErreurText);
						this.$toasted.show(`${ErreurText}`, {
							theme: "bubble",
							type: 'info',
							position: "bottom-center",
							duration: 5000
							
						});		
					})


			setTimeout(() => {
			}, 2000);
		}
		},
		home() {
			this.$router.push('/');
		}
	}
}
