import firebase from "firebase/compat/app";
import db from '../components/firebaseInit.js';

export class User {

    constructor() {
        this.email= '';
        this.name = '';
        this.nickname = '';
        this.telegram = ''
        this.tel = '';
        this.twitter = '';
        this.country = '';
        this.date = '';
        this.EmailStatus = false;
        this.TextEmailStatus = '';
        this.ProfilEcouteur = false;
    }

    getEmailStatus() {
        return (this.EmailStatus);
    }

    getTextEmailStatus() {
        return (this.TextEmailStatus);
    }

    getEmail() {
        return (this.email);
    }

    getname() {
        return (this.name);
    }

    setEmail(email) {
        this.email = email;
    }

    setProfilEcouteur(status) {
        this.ProfilEcouteur = status;
    }

    setEmailStatus(status){
        if (status == true) {
            this.EmailStatus = true;
            this.TextEmailStatus = "Email vérifié";
        }
        else {
            this.EmailStatus = false;
            this.TextEmailStatus = "Email non vérifié, Veuillez confirmer votre compte grâce au mail envoyer"; 
        }
    }    

    initData (data){
        this.name = data.name;
        this.nickname = data.nickname;
        this.telegram = data.telegram;
        this.tel = data.tel;
        this.twitter = data.twitter;
        this.country = data.country;
        this.date = data.date;
    }

    initWithFirestoreData(data) {
        if (firebase.auth().currentUser) {
            var docRef = db.collection("usersdata").doc(firebase.auth().currentUser.email);
            docRef.get().then((doc) => {
                if (doc.exists) {
                    this.email = firebase.auth().currentUser.email; 
                    this.name = doc.data().name;
                    this.nickname = doc.data().nickname;
                    this.telegram = doc.data().telegram;
                    this.tel = doc.data().tel;
                    this.twitter = doc.data().twitter;
                    this.country = doc.data().country;
                    this.date = doc.data().date;
                    this.user = doc.data().email;
                } else {
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }
        /*this.name = data.name;
        this.nickname = data.nickname;
        this.telegram = data.telegram;
        this.tel = data.tel;
        this.twitter = data.twitter;
        this.country = data.country;
        this.date = data.date;*/
    }    
}